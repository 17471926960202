.add-result-btn {
  margin: 20px auto !important;
  display: flex !important;
}

.result-line {
  border: 1px solid #e3e3e3;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 0 0px -1px 0px !important;

  > div {
    padding: 0px !important;
  }

  b {
    font-weight: 700;
  }

  .action-icons {
    display: flex;
    justify-content: center;

    // &:hover
    svg {
      opacity: 0.2;
    }

    svg:hover {
      opacity: 1;
    }

    .pencil-icon:hover path {
      fill: #008a60;
    }

    .trash-icon:hover path {
      fill: #af0505;
    }

    svg {
      cursor: pointer;

      rect {
        fill: none;
      }
    }
  }
}

.table-title {
  margin-top: 10px;
  // display: flex;
  // flex-direction: column;
  .print-statistic-event {
    display: block;
    width: 100%;
  }
  img {
    display: block;
    width: 130px;
    height: fit-content;
  }

  .print-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h1,
    h2,
    h3 {
      margin: 0;
    }
  }
}

.f-20 {
  font-size: 18px;
}

.player-cell-name {
  // max-width: 530px !important;
  // min-width: 530px !important;
  &.custom-width {
    min-width: 180px !important;
  }
}

.m-t-170 {
  // margin-top: 50px;
}

.rating-table.bg-white {
  div[role="presentation"] {
    overflow: hidden;
  }
}

.print-table {
  margin: 0px auto !important;
  position: relative;
  display: none;

  .w-50 {
    width: 50px !important;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #e3e3e3;
  }

  .name-big {
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
  }

  .table-title {
    display: flex;
    justify-content: space-between;
    // width: 96%;
    // position: absolute;
  }

  .desktop-table {
  }

  .line-print {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #e3e3e3;
    margin-bottom: -1px;
    box-sizing: border-box;

    &:nth-child(2n) {
      background-color: #f5f5f5;
    }

    &:first-child {
      margin-top: 10px;
    }

    & > div.delta-rating {
      width: 170px;
      justify-content: space-between;
      width: 340px;
    }

    & > div.place-name,
    & > div.delta-rating {
      align-items: center;
      display: flex;

      .t-right {
        text-align: right;
        font-weight: 700;
        font-size: 20px;
      }

      &.t-center {
        display: flex;
        align-items: center;
      }
    }
  }
}
.flex-row {
  display: flex;
  flex-direction: column;
  & > div {
    width: -webkit-fill-available;
  }
}
.place-activity {
  display: flex;
  width: 100%;
  align-items: center;

  .place-games {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .place-games-activity {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}

.past-delta {
  // position: absolute;
  // top: 0px;
  // right: 0px;

  .delta-plus,
  .delta-minus {
    font-size: 14px;
    span {
      // position: absolute;
      // top: -10px;
    }
  }
}

.current-rating {
  position: relative;
  font-size: 14px !important;
  display: flex;
}

.square-activity {
  width: 10px;
  height: 10px;
  border-radius: 5px;

  &.green {
    background: #0f8d3f;
  }

  &.red {
    background: #ff0000;
  }
}

.no-content-img {
  display: block;
  border-radius: 100%;
  margin: 20px auto 30px;
}

.player-place {
  width: 150px !important;
  justify-content: flex-start !important;
  display: flex !important;
  min-width: auto !important;
}

.icon-place {
  width: 25px;
  height: 25px;
  margin-top: 12px;
  margin-right: 5px;
  background: #fff;
  border-radius: 100%;
  border: 1px solid #c3c3c3;
  box-shadow: -1px -1px 7px -3px rgba(0, 0, 0, 0.3);
}

.show-activity {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 10px !important;

  button {
    background-color: #83180f;
  }

  .recalc-rating-btn button {
    margin-bottom: 20px;
    width: fit-content;
  }

  .recalc-rating-btn button {
    width: fit-content;
  }
}

.home-carousel {
  // background-color: unset;
  padding: 0px !important;
  background: none !important;
  box-shadow: none !important;
}

.delta-icon {
  justify-content: flex-start !important;
  align-items: center;
  min-width: 40px;
  display: flex;
  font-weight: 700;
  min-width: 60px !important;
  position: absolute;
  top: 0px;
  right: -21px;

  .simple-icon {
    width: 16px;

    &.hide-arrow {
      display: none;
    }

    &.icon-color-up {
      fill: #ff0000;
      transform: rotate(135deg);
    }

    &.icon-color-down {
      fill: #0f8d3f;
      transform: rotate(-45deg);
    }
  }

  .icon-color-up {
    color: #ff0000;
    font-size: 18px;
  }

  .icon-color-down {
    color: #0f8d3f;
    font-size: 14px;
  }
}
.print-container-btn {
  display: none;
}
.event-search-player {
  display: flex;
  justify-content: space-around;
  flex-direction: column !important;
  /* border: 1px solid #0a0a0a !important; */
  /* border-radius: 30px 10px 30px 10px; */
  /* background-color: #fff; */
  padding: 20px;
  height: 250px;
  margin-top: 88px;
  box-shadow: 0px 11px 48px 0px #edeff2;
  background-color: #ffffff;
  padding: 5px 15px;
  border-radius: 15px;

  &.no-margin {
    margin-top: 0px !important;
  }

  & > div {
    width: 100%;
    max-width: 100%;
    -webkit-flex-basis: unset;
    margin-bottom: 10px;
    & > div {
      padding: 10px;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column !important;
    max-width: 100%;
    margin-bottom: 10px;
    margin-top: 0px;
    max-width: 100% !important;

    // & > div {
    //   width: 100%;
    //   max-width: 100%;
    //   -webkit-flex-basis: unset;
    //   margin-bottom: 10px;
    // }

    .print-container {
      display: none;
    }
    .print-container-btn {
      display: block;
    }
  }
}
.best-stat-search-players-container {
  margin: 0px;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    flex-direction: column;

    .best-player-events {
      margin-right: 0px;
      margin-bottom: 10px;
    }
    .table-left-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 20px;
    }
    h4 {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 10px;
    }

    .table-line {
      padding: 5px;
      border-radius: 5px;
      // border: 1px solid #e3e3e3;
      width: 100%;
      margin-bottom: 10px;
    }
    .event-search-player > div {
      margin: 10px 0px !important;
    }
  }
  .event-search-player {
    svg,
    button {
      display: none;
    }
  }
  .event-search-player > div {
    margin: 0px;
    display: flex;
    align-items: center;
    height: fit-content;
    justify-content: space-between;
    div {
      width: 100%;
    }
  }
}
.best-player-events {
  flex-direction: column !important;
  margin-right: 15px;

  h4 {
    margin-bottom: 0px;
  }
  & > div {
    // width: 24%;
    display: flex;
    justify-content: left;
    &:first-child {
      border-bottom: 1px solid #e3e3e3;
    }
    @media (max-width: 500px) {
      width: 100%;
    }

    h6 {
      font-weight: 500;
      width: 100%;
      text-align: left;
      margin-top: 10px;
      color: #606060;
    }
  }
}
.rating-title {
}
.print {
  width: 50px;
  float: right;
  // margin-top: 15px;
  // margin-right: 8px;
  cursor: pointer;

  &:active {
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.5);
    border-radius: 100%;
  }
}

.r-bold {
  font-weight: 700;
  font-size: 18px;
  justify-content: flex-start !important;
  align-items: center !important;
}

.action-btns {
  justify-content: center !important;
}
.desktop-table {
  div[role="row"] {
    width: 100%;
    justify-content: start;
    background-color: #d5d5d5;
  }

  div[role="rowgroup"] {
    width: 100%;
  }
}

.even {
  background: #f5f5f5 !important;
}

.odd {
  background: #e7e7e7 !important;
}

.odd,
.even {
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  width: 100% !important;
  justify-content: space-between;

  & > div {
    border-bottom: none;
  }
}

.d-flex {
  display: flex;
}

.header-table {
  background-color: #d5d5d5;

  @media (min-width: 768px) {
    // & > div{
    max-width: 180px !important;
    // }

    & > div {
      max-width: 180px !important;
    }

    & > div {
      display: none !important;
    }
  }
}

.download-pdf {
  position: absolute;
  display: block !important;
  margin: 0 auto;
  width: 800px;
  right: 100000px;
}

.statistic-tabs {
  margin-bottom: 20px;
}

.player-games {
  .total-delta {
    font-weight: 700;
    font-size: 28px;
    margin-top: 20px;

    span {
      font-size: 28px;
    }
  }

  & > div {
    overflow: hidden;
    // text-align: center;
    // padding-bottom: 0px;
  }
}

.player-name {
  font-size: 14px;
  align-items: center;
  font-weight: 700;
  display: flex;
  position: relative;
  color: #1a202c;
  .player-verificated {
    position: absolute;
    top: 1px;
    left: -2px;
    // background: #fff;
    width: 15px !important;
    height: 15px !important;
    border-radius: 100%;
    padding: 1px;
    border: none;
  }
}

.delta-styles {
  display: flex;
  align-items: center;
  font-size: 18px;

  & > div {
    width: 90px;
    text-align: center;
    margin: 10px;
    font-weight: 700;
  }
}

.no-rating {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
  color: #0d458d;
  font-weight: 700;
}

.edit-player-name {
  text-align: center;
  color: #3a3a3a;
}

.t-row {
  display: flex;
  flex-direction: column !important;
}

.error {
  color: #af0505;
  text-align: center;
  width: 100%;
  font-weight: 700;
  margin-top: 10px;
}

.set-score input {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.delta-plus span {
  color: #0f8d3f !important;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  margin-left: 0px !important;
  min-width: 50px;
  background: #e9f4ee;
  padding: 2px 4px;
}

.t-center {
  text-align: center;
  display: flex;
  width: 100%;
}

.event-past-rating,
.places-arrows {
  width: 60px;
  font-size: 18px;
}

.delta-zero {
  width: 50px;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  margin-left: 0px !important;
}

.last-event-match {
  font-weight: 700;
  display: flex;

  div {
    width: auto;
    font-size: 12px;
    // margin-left: -20px;
  }
  .delta-plus,
  .delta-minus {
    span {
      font-size: 12px;
    }
  }
}

.delta-minus span {
  color: #ff0000 !important;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  margin-left: 0px !important;
  min-width: 50px;
  background: #ffd7d3;
  padding: 2px 4px;
}

.recalc-rating-btn {
  display: flex;
  justify-content: flex-end;

  button {
    margin-bottom: 20px;
  }
}

.show-history {
  cursor: pointer;

  svg {
    path {
      fill: #1a202c;
    }
  }
}

.player-event-place {
  font-weight: 700;
  margin-left: 20px;
}
.player-coach {
  position: absolute;
  bottom: -17px;
  right: 0px;
  font-size: 11px;
  font-weight: 700;
  @media (max-width: 500px) {
    bottom: -30px;
  }
}
.rating-line:first-child {
  border-bottom: 20px 20px 0px 0px;
}
.new-table {
  display: flex;
  flex-direction: column !important;
  overflow: scroll;

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba($color: #000, $alpha: 0.3);
  }

  @media (max-width: 500px) {
    .new-table .table-line {
      justify-content: flex-start;
    }
  }

  .table-header {
    line-height: 60px;
    background-color: #e3e3e3;

    margin-top: 0px !important;
    font-weight: 700;
    font-size: 16px;
    padding: 0px;

    border: 1px solid #0a0a0a !important;
    border-bottom: none !important;
    // border-radius: 30px 30px 0px 0px;
    .table-event {
      font-size: 12px !important;
    }
  }

  .table-line {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    line-height: 60px;
    /* border: 1px solid #0a0a0a; */
    /* margin-top: -1px; */
    background-color: #fff;
    padding: 3px 0px;
    color: #606060;
    font-weight: 500;
    @media (max-width: 500px) {
      background-color: unset !important;
    }
    & > div {
      display: flex;
    }

    &:nth-child(2n) {
      background-color: #f9f9f9;
    }

    &:hover {
      background-color: #cdcdcd;
      // height: 70px;
      // align-items: center;
      // margin-top: -11px;
      // z-index: 9;
      // margin-bottom: -11px;
      // box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
    }
  }

  .table-place {
    width: 50px;
    margin-right: 10px;
    text-align: center;
    // display: flex;
  }

  // .table-match-results {
  //   width: 100px;
  // }
  .table-event {
    width: 98px;
    justify-content: center;
    text-align: center;
    // border-left: 1px solid #0a0a0a;
    // border-right: 1px solid #0a0a0a;
    margin-right: -1px;
    position: relative;
  }

  .table-player-name {
    width: 240px;
    text-align: left;
    position: relative;
    @media (max-width: 500px) {
      width: 200px;
    }
  }

  .table-player-current-rating,
  .table-actions {
    width: 75px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    position: relative;
  }
}

.card-order-container {
  display: flex;
  position: absolute;
  right: 0px;
  top: 5px;
}

.card-order {
  width: 15px;
  height: 15px;
  margin-left: 2px;
  border-radius: 10px;
  border: 1px solid #979797;

  &.card-color-1 {
    background-color: #ff0000;
  }

  &.card-color-3 {
    background: #fdf800;
  }

  &.card-color-2 {
    background: #f06407;
  }
}

.count-games {
  display: flex;
  height: 15px;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 12px;
    margin-left: 3px;
  }
}

.adaptive-rating-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  .table-left-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    border-bottom: 2px solid #ff7d73;
    margin-bottom: 5px;
    padding-bottom: 5px;
    &:last-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  .table-line {
    // border: 1px solid #e3e3e3;
    width: 100%;
    // padding: 5px;
    border-radius: 5px;
    b {
      margin-left: 5px;
    }
    .table-player-name {
      display: flex;
      // margin: 0px 5px;
      padding: 0px 5px;
      // justify-content: center;
      align-items: center;

      @media (max-width: 500px) {
        justify-content: center;
      }
    }
  }
  h2 {
    background: #fff;
    text-align: center;
    margin-top: 30px;
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    text-transform: none;
  }
  & > div {
    width: 100%;
    max-width: 100%;
    margin-right: 10px;
  }
}

.qr-code {
  width: 100px;
}

.player-image {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  margin-right: 5px;
}

@media (max-width: 500px) {
  .adaptive-rating-table {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
      width: 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }

  .show-activity button {
    width: 100%;
  }

  .print-container {
    display: block;
    margin: 0 auto !important;
    width: 100px;
    max-width: unset !important;

    img {
      float: none;
      margin: 0 auto;
    }
  }
}
.delta-decimals {
  position: relative;
  padding-right: 40px;
  width: 95px;
  justify-content: center;
  display: flex;
}

.top-5 {
  & > div:first-child {
    width: 200px;
  }
  .top-5-statistic {
    width: 60%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}
@media (max-width: 500px) {
  .table-left-container {
    // border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px !important;
    margin-top: 10px !important;
  }
}
.table-line .top-5:last-child {
  border-bottom: none;
}
.best-line {
  width: 10%;
  min-width: 95px;
  height: 4px;
  background-color: #ff7d73;
}
.best-title {
  color: #050505;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 20px;
}
.top-5-container {
  box-shadow: 0px 11px 48px 0px #edeff2;
  background-color: #ffffff;

  padding: 5px 15px;
  border-radius: 15px;
}
.rating-header-table {
  background-color: unset !important;
}
.show-only-participants {
  border: 2px solid #db0a40;
  border-left: 5px solid #db0a40;
  color: #db0a40;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  padding: 5px 10px;
  position: relative;
  transform: skew(170deg);
  background-color: #fff;
  cursor: pointer;
  span {
    display: block;
    transform: skew(-170deg);
  }
}
.total-players {
  text-align: left;
}
.desktop-table {
  .player-name-games {
    span {
      line-height: normal;
    }
    & > div {
      height: 100%;
      display: flex;
      align-items: flex-end;
      line-height: normal;
    }
    .place-games {
      display: flex;
      justify-content: flex-start;
    }
  }
  .table-left-container {
    border-right: 1px solid #a7a7a7;
    width: 510px;
  }
}
.afisha-container-home-page {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  justify-content: center;

  @media (max-width: 500px) {
    flex-direction: column;
    .afisha-item {
      width: 100% !important;
    }
  }
  .afisha-item {
    // background-image: url("../../../../images/pattern-bg.png");
    width: 50%;
    /* border: 1px solid #919191; */
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 5px;
    border-radius: 7px;
    position: relative;
    box-shadow: 2px -2px 16px 0px rgba(0, 0, 0, 0.2);

    .afisha-title {
      color: #0d458d;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  .cancelled-label {
    font-style: italic;
  }
  .cancelled-container {
    border-top: 1px solid #e3e3e3;
    margin-top: 5px;
    background: #ffe6e6;
    border-radius: 4px;
    padding: 5px 0px;
  }
  .afisha-event-title {
    font-size: 24px;
    font-weight: 500;
    color: #000;
  }
  .afisha-description {
    font-size: 18px;
    margin-top: 10px;
  }
  .afisha-date {
    font-size: 18px;
    margin: 10px 0px;
  }
  .top-cup-logo {
    position: absolute;
    width: 40px;
    right: 5px;
    top: 10px;
    border-radius: 10px;
  }
  .best-line {
    margin: 0 auto;
    margin-bottom: 10px;
  }
}
// .table-place {
//   .place-league-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     padding: 0;
//     .place-league {
//       display: flex;
//       align-items: center;
//       height: 20px;
//       span {
//         font-weight: 700;
//         color: #1a202c;
//       }
//       svg {
//         width: 13px;
//       }
//     }
//   }
// }
.is-saturday {
  @media (max-width: 500px) {
    padding-top: 45px !important;
  }

  &.is-registration {
    .afisha-tv {
      .time-track {
        color: #fff;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 15px;
        background-color: #daca41 !important;
        font-style: italic;
      }
    }
  }
  &.is-progress {
    .afisha-tv {
      .time-track {
        color: #fff;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 15px;
        background-color: #01a151;
      }
    }
  }
  &.is-end {
    .afisha-tv {
      .time-track {
        color: #fff;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 15px;
        background-color: #8d8d8d;
      }
    }
  }
  .afisha-tv {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 5px;
    font-size: 10px;
  }
  .you-tube-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .see-translation {
    display: flex;
    justify-content: flex-end;
  }
  .see {
    line-height: 11px;
  }
}

.is-sunday {
  @media (max-width: 500px) {
    padding-top: 45px !important;
  }
  &.is-registration {
    .afisha-tv {
      .time-track {
        color: #fff;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 15px;
        background-color: #daca41;
      }
    }
  }
  &.is-progress {
    .afisha-tv {
      .time-track {
        color: #fff;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 15px;
        background-color: #01a151;
      }
    }
  }
  &.is-end {
    .afisha-tv {
      .time-track {
        color: #fff;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 15px;
        background-color: #cacaca;
      }
    }
  }
  .afisha-tv {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 5px;
    font-size: 10px;
  }
  .you-tube-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .see-translation {
    display: flex;
    justify-content: flex-end;
  }
  .see {
    line-height: 11px;
  }
}
